<template>
  <div id="app">
    <!-- Farming Energy Container -->
    <div class="farming-energy">
      <h2>Farming Energy</h2>
    </div>

    <!-- Footer with 5 Square Buttons -->
    <footer class="footer" ref="footer">
      <button 
        class="footer-btn" 
        :class="{ active: isModalOpen && currentContent === 'Boost' }" 
        @click="openModal('Boost')">
        <i class="fas fa-rocket"></i>
        <span class="btn-name">Boost</span>
      </button>
      <button 
        class="footer-btn" 
        :class="{ active: isModalOpen && currentContent === 'Ranking' }" 
        @click="openModal('Ranking')">
        <i class="fas fa-trophy"></i>
        <span class="btn-name">Ranking</span>
      </button>
      <button 
        class="footer-btn" 
        :class="{ active: isModalOpen && currentContent === 'Dice' }" 
        @click="openModal('Dice')">
        <i class="fas fa-dice"></i>
        <span class="btn-name">Throw Dice</span>
      </button>
      <button 
        class="footer-btn" 
        :class="{ active: isModalOpen && currentContent === 'Referral' }" 
        @click="openModal('Referral')">
        <i class="fas fa-share"></i>
        <span class="btn-name">Referral</span>
      </button>
      <button 
        class="footer-btn" 
        :class="{ active: isModalOpen && currentContent === 'Tasks' }" 
        @click="openModal('Tasks')">
        <i class="fas fa-scroll"></i>
        <span class="btn-name">Tasks</span>
      </button>
    </footer>

    <!-- Modal for displaying content -->
    <transition name="fade">
      <div v-if="isModalOpen" class="modal-container">
        <button class="close-btn" @click="closeModal">×</button>
        <h2>{{ currentContent }}</h2>
        <p>This is the content for {{ currentContent }}</p>
      </div>
    </transition>
  </div>
</template>


<script>
export default {
  name: 'App',
  data() {
    return {
      isModalOpen: false,
      currentContent: ''
    };
  },
  methods: {
    openModal(content) {
      this.currentContent = content.charAt(0).toUpperCase() + content.slice(1); // Capitalize the content
      this.isModalOpen = true;
      this.$nextTick(() => {
        const modal = document.querySelector('.modal-container');
        if (modal) {
          // Adding a slight delay before adding 'show' class
          setTimeout(() => {
            modal.classList.add('show');
          }, 50); // 50ms delay before adding the class
        }
      });
    },
    closeModal() {
      const modal = document.querySelector('.modal-container');
      if (modal) {
        modal.classList.remove('show');
      }
      // Match this timeout to the longer CSS transition duration
      setTimeout(() => {
        this.isModalOpen = false;
      }, 1000); // Match this duration to the CSS transition duration (1s)
    }
  }
};
</script>



<style>
/* Basic reset */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Ensure no scroll bars */
  font-family: 'Poppins', sans-serif;
}

/* Ensure the app takes up full viewport */
#app {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: url('@/assets/background.jpg') no-repeat center center fixed; /* Apply the background image */
  background-size: cover; /* Ensure the background covers the entire element */
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  justify-content: flex-end; /* Align items to the bottom */
  align-items: center; /* Center items horizontally */
  padding-bottom: 160px; /* Adjust to make space for both footer and energy container */
}

.farming-energy {
  background: rgba(5, 223, 252, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(18.2px);
  -webkit-backdrop-filter: blur(18.2px);
  padding: 20px;
  width: calc(100% - 50px); /* Full width with some padding */
  height: 30px; /* Set a specific height */
  position: fixed; /* Fix position relative to the viewport */
  bottom: 90px; /* Place it above the footer */
  text-align: center; /* Center the text horizontally */
  color: rgba(255, 255, 255, 0.9); /* Text color */
  z-index: 5; /* Ensure it stays above other elements */
  display: flex; /* Use flexbox for alignment */
    font-size: 12px; /* Increased font size */
  box-shadow: 0 4px 30px rgba(5, 223, 252, 0.2), 0 0 15px rgba(5, 223, 252.2); /* Added glow */

  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally (optional, since text-align is already set) */
}



/* Footer styling */
.footer {
  display: flex;
  justify-content: space-around;
  width: calc(100% - 10px); /* Full width with 5px gap on each side */
  position: fixed; /* Keep footer fixed */
  bottom: 0; /* Align to the bottom */
  gap: 5px; /* 5px gap between buttons */
  margin-bottom: 0; /* Ensure the footer stays at the bottom */
}

/* Button styling */
.footer-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px; /* Increased width */
  height: 65px; /* Increased height */
  background: rgba(123, 26, 26, 0);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(5, 223, 252, 0.2), 0 0 15px rgba(5, 223, 252.2); /* Added glow */
  backdrop-filter: blur(5.1px);
  -webkit-backdrop-filter: blur(5.1px);
  border: 1px solid rgba(5, 223, 252, 0.2);
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 9px; /* Increased font size */
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transform transition */
}
/* Active button styling when modal is open */
.footer-btn.active:hover {
  background-color: rgba(5, 223, 252, 0.2); /* Change hover color when active */
}
/* Hover effect with scaling */
.footer-btn:hover {
  transform: scale(1.05); /* Slightly grow on hover */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 0 0 20px rgba(5, 223, 252, 0.4); /* Glow on hover */
}
/* Icon styling */
.footer-btn i {
  font-size: 26px; /* Increased icon size */
  margin-bottom: 4px;
  color: rgba(255, 255, 255, 0.5);
}
/* Responsive adjustments */
@media (max-width: 600px) { /* Adjust for smaller screens */
  .footer {
    width: calc(100% - 10px); /* Keep full width with gaps */
    padding: 5px; /* Adjust padding if necessary */
  }
}

/* Modal container styling */
.modal-container {
  position: fixed;
  bottom: 0; /* Start from the bottom */
  left: 0;
  right: 0; /* Stretch across the screen */
  height: 80%; /* Cover 80% of the screen */
  background: rgba(255, 255, 255, 0.3); /* Light glassmorphism background */
  border-radius: 16px; /* Rounded corners */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* Soft shadow */
  backdrop-filter: blur(5.1px); /* Blur effect for background */
  -webkit-backdrop-filter: blur(5.1px); /* For Safari */
  border: 1px solid rgba(5, 223, 252, 0.6); /* Light border */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center the content */
  z-index: 10; /* Above footer */
  transform: translateY(100%); /* Start off-screen */
  opacity: 0; /* Start hidden */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Slower transition for both */
}

.modal-container.show {
  transform: translateY(0); /* Slide up into view */
  opacity: 1; /* Fade in */
}

/* Close button styling */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: rgba(5, 223, 252, 2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1), 2 0 20px rgba(5, 223, 252, 0.4); /* Glow on hover */
  font-size: 28px;
  cursor: pointer;
}

/* Fade transition */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease-in-out; /* Match the modal duration */
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}


</style>
